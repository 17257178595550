import React, { Suspense, useEffect, useRef, useState } from "react";
import { Canvas } from "@react-three/fiber";
import {  MeshReflectorMaterial } from "@react-three/drei";

import gsap from "gsap";
import { Model } from "./Model";
import Lights from "./Lights";
import Camera from "./Camera";
import { Perf } from "r3f-perf";
import Loading from "./Loading";



function Experience() {
  const cameraKeyFrames = {
    load: { position: [13, 20, 19], rotation: [-0.74, 0.38, 0.37] },
    idle: { position: [6, 4, 2], rotation: [-0.54, 1, 0.43] },
    Computer: { position: [0.25, 1.6, -1.3], rotation: [0, -0.01, 0] },
    skills: { position: [1, 2.5, -0.6], rotation: [0, 1.57, 0] },
    art: { position: [1.5, 3.2, 0.5], rotation: [0, 0, 0] },
    about: { position: [-1, 1.8, -1.3], rotation: [-0.84, 0, 0] },
  };

  const CameraRef = useRef();
  const [modelIsLoaded, setmodelIsLoaded] = useState(false);
  const [CanAnimate, setCanAnimate] = useState(false);
  const [CurrentKeyFrame, setCurrentKeyFrame] = useState("idle");

  useEffect(() => {
    const position = cameraKeyFrames.idle.position;
    const rotation = cameraKeyFrames.idle.rotation;

    if (CameraRef.current && modelIsLoaded) {
      gsap.to(CameraRef.current.position, {
        x: position[0],
        y: position[1],
        z: position[2],
        duration: 1,
        delay: 1,
        ease: "circ.out",
        immediateRender: false,
        onComplete: () => {
          setCanAnimate(true);
        },
      });

      gsap.to(CameraRef.current.rotation, {
        x: rotation[0],
        y: rotation[1],
        z: rotation[2],
        duration: 1,
        delay: 1,
        ease: "back.out(1.7)",
        immediateRender: false,
      });
    }
  }, [modelIsLoaded]);

  // ...

  const hundleLoad = () => {
    setmodelIsLoaded(true);
  };

  const hundleChangeCurrentKeyFrame = (key) => {
    if (
      CameraRef.current &&
      CanAnimate &&
      CameraRef.current.position.x !== cameraKeyFrames[key].position.x &&
      CurrentKeyFrame !== key
    ) {
      setCanAnimate(false);
      const position = cameraKeyFrames[key].position;
      const rotation = cameraKeyFrames[key].rotation;

      gsap.to(CameraRef.current.position, {
        x: position[0],
        y: position[1],
        z: position[2],
        duration: 1,
        immediateRender: false,
        onComplete: function () {
          setCanAnimate(true);
          setCurrentKeyFrame(key);
        },
      });

      gsap.to(CameraRef.current.rotation, {
        x: rotation[0],
        y: rotation[1],
        z: rotation[2],
        duration: 1,
        immediateRender: false,
      });
    }
  };

  return (
    <Canvas>
      <Camera CameraRef={CameraRef} />
      <Lights />
      <Suspense fallback={<Loading />}>
        <Model
          hundleLoad={hundleLoad}
          hundleChangeCurrentKeyFrame={hundleChangeCurrentKeyFrame}
        />
      </Suspense>
      <color attach={"background"} args={["#17233e"]} background />
      <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -0.5, 0]}>
        <planeGeometry args={[100, 100, 100]} attach={"geometry"} />
        <MeshReflectorMaterial
          reflectivity={3}
          refractionRatio={5}
          attach={"material"}
          color={"#475c8d"}
        />
      </mesh>
      {/* <OrbitControls /> */}
      {/* <Perf /> */}
    </Canvas>
  );
}

export default Experience;

import Experience from './Components/Experience';

function App() {
  return (
    <div className="App">
        <Experience />
        <img src="images/full-logo.svg" id='logo' alt='logo'/>
    </div> 
  );
}

export default App;

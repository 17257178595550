// import { Environment } from "@react-three/drei";
// import {
//   Bloom,
//   EffectComposer,
// } from "@react-three/postprocessing";
// import { useControls } from "leva";




function Lights() {
  // useHelper(pointRef, THREE.PointLightHelper);
  // useHelper(rectAreaLight, THREE.PointLightHelper);
  return (
    <>
      {/* <Environment preset="night" background={false} near={4} far={150} /> */}
      <ambientLight   intensity={.7} color={"#dbf6ff"}/>
      <rectAreaLight position={[0.2, 6.5, 4.39]} scale={10} />
      <pointLight  position={[3.93, 6.09, -1.5]} color={"ffb85b"} intensity={.4}/>
    </>
  );
}

export default Lights;

import React, { useEffect, useRef } from "react";
import { useGLTF, useAnimations, Html } from "@react-three/drei";

export function Model({ hundleLoad, hundleChangeCurrentKeyFrame }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/Objects/ModelV2.glb");
  const { actions, names } = useAnimations(animations, group);

  useEffect(() => {
    hundleLoad();
    // Reset and fade in animation after an index has been changed
    actions[names[0]].play();
    // In the clean-up phase, fade it out
    // return () => actions[names[1]].fadeOut(0.5)
  }, [actions, names , hundleLoad]);

  return (
    <group ref={group} dispose={null}>
      <mesh
        position={[-2.5, 2.5, -0.6]}
        rotation={[0, Math.PI / 2, 0]}
        onPointerOver={() => {
          hundleChangeCurrentKeyFrame("skills");
        }}
        onPointerOut={() => {
          hundleChangeCurrentKeyFrame("idle");
        }}
      >
        <planeGeometry args={[2, 2]} />
        <meshBasicMaterial visible={false} />
      </mesh>

      <mesh
        name="Cube036"
        castShadow
        receiveShadow
        geometry={nodes.Cube036.geometry}
        material={materials["Material.009"]}
        position={[0.2, 1.61, -2.65]}
        scale={[0.44, 0.37, 0.37]}
      >
        <Html
          position={[0, 0, 0.86]}
          transform
          distanceFactor={1}
          // rotation={[0, 0, Math.PI / 2]}
          scale={.45}
          occlude="blending"
        >
          <iframe
            src="http://win98.netlify.app/"
            title="windows"
            onPointerOver={() => {
              hundleChangeCurrentKeyFrame("Computer");
            }}
            onPointerOut={() => {
              hundleChangeCurrentKeyFrame("idle");
            }}
          ></iframe>
        </Html>
      </mesh>

      <mesh
        position={[1.4, 3.2, -2.8]}
        rotation={[0, 0, 0]}
        onPointerOver={() => {
          hundleChangeCurrentKeyFrame("art");
        }}
        onPointerOut={() => {
          hundleChangeCurrentKeyFrame("idle");
        }}
      >
        <planeGeometry args={[2.1, 2.1]} />
        <meshBasicMaterial visible={false} />
      </mesh>

      <mesh
        position={[-1, 1, -1.9]}
        rotation={[-Math.PI / 2, 0, 0]}
        onPointerOver={() => {
          hundleChangeCurrentKeyFrame("about");
        }}
        onPointerOut={() => {
          hundleChangeCurrentKeyFrame("idle");
        }}
      >
        <planeGeometry args={[0.7, 0.5]} />
        <meshBasicMaterial visible={false} />
      </mesh>

      <group name="Cube" position={[0, 2.65, 0]} scale={3.05}>
        <mesh
          name="Cube_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube_1.geometry}
          material={materials.bake}
        >
          <Html
            position={[-0.98,.34, -0.2]}
            transform
            distanceFactor={11}
            rotation={[ 0 , Math.PI / 2, 0]}
            scale={0.1}
          >
            <h1>My Skills</h1>
          </Html>

          <Html 
            position={[.45,.5, -1]}
            transform
            distanceFactor={11}
            rotation={[ 0 , 0, 0]}
            scale={0.1}
          >
            <h1>Art</h1>
          </Html>
        </mesh>
        <mesh
          name="Cube_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube_2.geometry}
          material={materials.bake}
        />
      </group>
      <group>
        <mesh
          name="Cube037"
          castShadow
          receiveShadow
          geometry={nodes.Cube037.geometry}
          material={materials["Material.006"]}
          position={[-1.34, 0.99, -2.31]}
          rotation={[-0.49, 0.7, 0.35]}
          scale={[0.1, 0.1, 0.01]}
        />
        <mesh
          name="Cube038"
          castShadow
          receiveShadow
          geometry={nodes.Cube038.geometry}
          material={materials["Material.031"]}
          position={[-1.34, 0.99, -2.31]}
          rotation={[-0.49, 0.7, 0.35]}
          scale={[0.1, 0.1, 0.01]}
        />
        <group
          name="Cube028"
          position={[-0.8, 0.93, -1.93]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[-0.13, -0.02, -0.21]}
        >
          <mesh
            name="Cube029_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube029_1.geometry}
            material={materials["Material.006"]}
          />
          <mesh
            name="Cube029_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube029_2.geometry}
            material={materials.Material}
          >
            <Html
              position={[-2, -0.6, -0.3]}
              transform
              distanceFactor={10.5}
              rotation={[-Math.PI / 2, 0, 0]}
              scale={0.2}
              className="about"
              pointerEvents="none"
            >
              <h1>About me</h1>
              <p>
              I'm Hamza khazri, I am 21 years old. A boy from Tunisia with a passion for programming. You can visit my GitHub account to see some of my projects. For feedback please contact me via email or any of my other social media below.
              </p>
            </Html>
          </mesh>
        </group>
      </group>

      <group name="Scene">
        <group
          name="metarig001"
          position={[-0.17, -0.77, -0.63]}
          rotation={[Math.PI, -0.05, Math.PI]}
          scale={1.1}
        >
          <primitive object={nodes.spine} />
          <group name="Cube040">
            <skinnedMesh
              name="Cube047"
              geometry={nodes.Cube047.geometry}
              material={materials.skin}
              skeleton={nodes.Cube047.skeleton}
            />
            <skinnedMesh
              name="Cube047_1"
              geometry={nodes.Cube047_1.geometry}
              material={materials.shirt}
              skeleton={nodes.Cube047_1.skeleton}
            />
            <skinnedMesh
              name="Cube047_2"
              geometry={nodes.Cube047_2.geometry}
              material={materials.jins}
              skeleton={nodes.Cube047_2.skeleton}
            />
            <skinnedMesh
              name="Cube047_3"
              geometry={nodes.Cube047_3.geometry}
              material={materials.shose}
              skeleton={nodes.Cube047_3.skeleton}
            />
            <skinnedMesh
              name="Cube047_4"
              geometry={nodes.Cube047_4.geometry}
              material={materials["Material.010"]}
              skeleton={nodes.Cube047_4.skeleton}
            />
            <skinnedMesh
              name="Cube047_5"
              geometry={nodes.Cube047_5.geometry}
              material={materials["Material.003"]}
              skeleton={nodes.Cube047_5.skeleton}
            />
            <skinnedMesh
              name="Cube047_6"
              geometry={nodes.Cube047_6.geometry}
              material={materials["skin.002"]}
              skeleton={nodes.Cube047_6.skeleton}
            />
            <skinnedMesh
              name="Cube047_7"
              geometry={nodes.Cube047_7.geometry}
              material={materials["Material.017"]}
              skeleton={nodes.Cube047_7.skeleton}
            />
          </group>
          <skinnedMesh
            name="Sphere"
            geometry={nodes.Sphere.geometry}
            material={materials["Material.009"]}
            skeleton={nodes.Sphere.skeleton}
          />
        </group>
        <group
          name="WGT-rig_spine_fk"
          position={[0, 1.18, -0.02]}
          rotation={[1.82, 0, 0]}
          scale={0.15}
        />
        <group
          name="WGT-rig_spine_fk001"
          position={[0, 1.31, 0]}
          rotation={[1.68, 0, 0]}
          scale={0.13}
        />
        <group
          name="WGT-rig_spine_fk002"
          position={[0, 1.31, 0]}
          rotation={[1.54, 0, 0]}
          scale={0.16}
        />
        <group
          name="WGT-rig_spine_fk003"
          position={[0, 1.47, -0.01]}
          rotation={[1.54, 0, 0]}
          scale={0.19}
        />
        <group
          name="WGT-rig_tweak_spine"
          position={[0, 1.03, -0.05]}
          rotation={[1.82, 0, 0]}
          scale={0.08}
        />
        <group
          name="WGT-rig_tweak_spine001"
          position={[0, 1.18, -0.02]}
          rotation={[1.68, 0, 0]}
          scale={0.07}
        />
        <group
          name="WGT-rig_tweak_spine002"
          position={[0, 1.31, 0]}
          rotation={[1.54, 0, 0]}
          scale={0.08}
        />
        <group
          name="WGT-rig_tweak_spine003"
          position={[0, 1.47, -0.01]}
          rotation={[1.54, 0, 0]}
          scale={0.1}
        />
        <group
          name="WGT-rig_tweak_spine004"
          position={[0, 1.66, -0.01]}
          rotation={[1.88, 0, 0]}
          scale={0.03}
        />
        <group name="WGT-rig_torso" position={[0, 1.1, -0.03]} scale={0.38} />
        <group
          name="WGT-rig_hips"
          position={[0, 1.03, -0.05]}
          rotation={[1.82, 0, 0]}
          scale={0.16}
        />
        <group
          name="WGT-rig_chest"
          position={[0, 1.47, -0.01]}
          rotation={[1.54, 0, 0]}
          scale={0.21}
        />
        <group
          name="WGT-rig_breastL"
          position={[0.12, 1.46, -0.05]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={0.14}
        />
        <group
          name="WGT-rig_breastR"
          position={[-0.12, 1.46, -0.05]}
          rotation={[0, 0, Math.PI]}
          scale={-0.14}
        />
        <group
          name="WGT-rig_shoulderL"
          position={[0.02, 1.61, 0.07]}
          rotation={[-0.25, -1.03, -0.22]}
          scale={0.18}
        />
        <group
          name="WGT-rig_upper_arm_parentL"
          position={[0.2, 1.56, -0.03]}
          rotation={[-1.77, -1.51, -1.07]}
          scale={0.12}
        />
        <group
          name="WGT-rig_upper_arm_fkL"
          position={[0.2, 1.56, -0.03]}
          rotation={[-1.77, -1.51, -1.07]}
          scale={0.48}
        />
        <group
          name="WGT-rig_forearm_fkL"
          position={[0.67, 1.53, -0.02]}
          rotation={[-0.45, -1.54, 0.25]}
          scale={0.38}
        />
        <group
          name="WGT-rig_hand_fkL"
          position={[1.05, 1.53, -0.03]}
          rotation={[-2.28, -1.53, -1.58]}
          scale={0.2}
        />
        <group
          name="WGT-rig_upper_arm_ikL"
          position={[0.2, 1.56, -0.03]}
          rotation={[-1.77, -1.51, -1.07]}
          scale={0.48}
        />
        <group
          name="WGT-rig_upper_arm_ik_targetL"
          position={[0.65, 0.88, 0.53]}
          rotation={[0.87, -0.03, 0.01]}
          scale={0.11}
        />
        <group
          name="WGT-rig_hand_ikL"
          position={[1.05, 1.53, -0.03]}
          rotation={[-2.28, -1.53, -1.58]}
          scale={0.2}
        />
        <group
          name="WGT-rig_VIS_upper_arm_ik_poleL"
          position={[0.67, 1.53, -0.02]}
          rotation={[Math.PI / 2, 0, 0.68]}
          scale={0.85}
        />
        <group
          name="WGT-rig_upper_arm_tweakL"
          position={[0.2, 1.56, -0.03]}
          rotation={[-1.77, -1.51, -1.07]}
          scale={0.12}
        />
        <group
          name="WGT-rig_upper_arm_tweakL001"
          position={[0.44, 1.55, -0.03]}
          rotation={[-1.77, -1.51, -1.07]}
          scale={0.12}
        />
        <group
          name="WGT-rig_forearm_tweakL"
          position={[0.67, 1.53, -0.02]}
          rotation={[-0.45, -1.54, 0.25]}
          scale={0.09}
        />
        <group
          name="WGT-rig_forearm_tweakL001"
          position={[0.86, 1.53, -0.03]}
          rotation={[-0.45, -1.54, 0.25]}
          scale={0.09}
        />
        <group
          name="WGT-rig_hand_tweakL"
          position={[1.05, 1.53, -0.03]}
          rotation={[-2.28, -1.53, -1.58]}
          scale={0.05}
        />
        <group
          name="WGT-rig_shoulderR"
          position={[-0.02, 1.61, 0.07]}
          rotation={[2.89, -1.03, -0.22]}
          scale={-0.18}
        />
        <group
          name="WGT-rig_upper_arm_parentR"
          position={[-0.2, 1.56, -0.03]}
          rotation={[1.38, -1.51, -1.07]}
          scale={-0.12}
        />
        <group
          name="WGT-rig_upper_arm_fkR"
          position={[-0.2, 1.56, -0.03]}
          rotation={[1.38, -1.51, -1.07]}
          scale={-0.48}
        />
        <group
          name="WGT-rig_forearm_fkR"
          position={[-0.67, 1.53, -0.02]}
          rotation={[2.7, -1.54, 0.25]}
          scale={-0.38}
        />
        <group
          name="WGT-rig_hand_fkR"
          position={[-1.05, 1.53, -0.03]}
          rotation={[0.86, -1.53, -1.58]}
          scale={-0.2}
        />
        <group
          name="WGT-rig_upper_arm_ikR"
          position={[-0.2, 1.56, -0.03]}
          rotation={[1.38, -1.51, -1.07]}
          scale={-0.48}
        />
        <group
          name="WGT-rig_upper_arm_ik_targetR"
          position={[-0.65, 0.88, 0.53]}
          rotation={[-2.27, -0.03, 0.01]}
          scale={-0.11}
        />
        <group
          name="WGT-rig_hand_ikR"
          position={[-1.05, 1.53, -0.03]}
          rotation={[0.86, -1.53, -1.58]}
          scale={-0.2}
        />
        <group
          name="WGT-rig_VIS_upper_arm_ik_poleR"
          position={[-0.67, 1.53, -0.02]}
          rotation={[-Math.PI / 2, 0, 0.68]}
          scale={-0.85}
        />
        <group
          name="WGT-rig_upper_arm_tweakR"
          position={[-0.2, 1.56, -0.03]}
          rotation={[1.38, -1.51, -1.07]}
          scale={-0.12}
        />
        <group
          name="WGT-rig_upper_arm_tweakR001"
          position={[-0.44, 1.55, -0.03]}
          rotation={[1.38, -1.51, -1.07]}
          scale={-0.12}
        />
        <group
          name="WGT-rig_forearm_tweakR"
          position={[-0.67, 1.53, -0.02]}
          rotation={[2.7, -1.54, 0.25]}
          scale={-0.09}
        />
        <group
          name="WGT-rig_forearm_tweakR001"
          position={[-0.86, 1.53, -0.03]}
          rotation={[2.7, -1.54, 0.25]}
          scale={-0.09}
        />
        <group
          name="WGT-rig_hand_tweakR"
          position={[-1.05, 1.53, -0.03]}
          rotation={[0.86, -1.53, -1.58]}
          scale={-0.05}
        />
        <group
          name="WGT-rig_neck"
          position={[0, 1.66, -0.01]}
          rotation={[1.86, 0, 0]}
          scale={0.13}
        />
        <group
          name="WGT-rig_head"
          position={[0, 1.78, 0.02]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.54}
        />
        <group
          name="WGT-rig_tweak_spine005"
          position={[0, 1.72, 0.01]}
          rotation={[1.83, 0, 0]}
          scale={0.03}
        />
        <group
          name="WGT-rig_thigh_parentL"
          position={[0.1, 1.09, -0.01]}
          rotation={[-1.6, -0.13, 1.04]}
          scale={0.12}
        />
        <group
          name="WGT-rig_thigh_fkL"
          position={[0.1, 1.09, -0.01]}
          rotation={[-1.6, -0.13, 1.04]}
          scale={0.46}
        />
        <group
          name="WGT-rig_shin_fkL"
          position={[0.16, 0.63, 0.01]}
          rotation={[-1.53, -0.01, 1.05]}
          scale={0.54}
        />
        <group
          name="WGT-rig_foot_fkL"
          position={[0.16, 0.09, -0.01]}
          rotation={[-2.76, 0, 0]}
          scale={0.18}
        />
        <group
          name="WGT-rig_toe_fkL"
          position={[0.16, 0.02, 0.16]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.15}
        />
        <group
          name="WGT-rig_thigh_ikL"
          position={[0.1, 1.09, -0.01]}
          rotation={[-1.6, -0.13, 1.04]}
          scale={0.46}
        />
        <group
          name="WGT-rig_thigh_ik_targetL"
          position={[1.02, 0.69, 0.51]}
          rotation={[-0.11, 1.04, 0.06]}
          scale={0.13}
        />
        <group
          name="WGT-rig_foot_ikL"
          position={[0.16, 0.09, -0.01]}
          scale={0.18}
        />
        <group
          name="WGT-rig_VIS_thigh_ik_poleL"
          position={[0.16, 0.63, 0.01]}
          rotation={[Math.PI / 2, 0, 1.06]}
        />
        <group
          name="WGT-rig_thigh_tweakL"
          position={[0.1, 1.09, -0.01]}
          rotation={[-1.6, -0.13, 1.04]}
          scale={0.12}
        />
        <group
          name="WGT-rig_thigh_tweakL001"
          position={[0.13, 0.86, 0]}
          rotation={[-1.6, -0.13, 1.04]}
          scale={0.12}
        />
        <group
          name="WGT-rig_shin_tweakL"
          position={[0.16, 0.63, 0.01]}
          rotation={[-1.53, -0.01, 1.05]}
          scale={0.14}
        />
        <group
          name="WGT-rig_shin_tweakL001"
          position={[0.16, 0.36, 0]}
          rotation={[-1.53, -0.01, 1.05]}
          scale={0.14}
        />
        <group
          name="WGT-rig_foot_tweakL"
          position={[0.16, 0.09, -0.01]}
          rotation={[-2.76, 0, 0]}
          scale={0.05}
        />
        <group
          name="WGT-rig_foot_spin_ikL"
          position={[0.16, 0.02, 0.16]}
          scale={0.09}
        />
        <group
          name="WGT-rig_foot_heel_ikL"
          position={[0.16, 0.09, -0.01]}
          scale={0.09}
        />
        <group
          name="WGT-rig_toe_ikL"
          position={[0.16, 0.02, 0.16]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={0.15}
        />
        <group
          name="WGT-rig_thigh_parentR"
          position={[-0.1, 1.09, -0.01]}
          rotation={[1.54, -0.13, 1.04]}
          scale={-0.12}
        />
        <group
          name="WGT-rig_thigh_fkR"
          position={[-0.1, 1.09, -0.01]}
          rotation={[1.54, -0.13, 1.04]}
          scale={-0.46}
        />
        <group
          name="WGT-rig_shin_fkR"
          position={[-0.16, 0.63, 0.01]}
          rotation={[1.61, -0.01, 1.05]}
          scale={-0.54}
        />
        <group
          name="WGT-rig_foot_fkR"
          position={[-0.16, 0.09, -0.01]}
          rotation={[0.38, 0, 0]}
          scale={-0.18}
        />
        <group
          name="WGT-rig_toe_fkR"
          position={[-0.16, 0.02, 0.16]}
          rotation={[0, 0, Math.PI]}
          scale={-0.15}
        />
        <group
          name="WGT-rig_thigh_ikR"
          position={[-0.1, 1.09, -0.01]}
          rotation={[1.54, -0.13, 1.04]}
          scale={-0.46}
        />
        <group
          name="WGT-rig_thigh_ik_targetR"
          position={[-1.02, 0.69, 0.51]}
          rotation={[3.03, 1.04, 0.06]}
          scale={-0.13}
        />
        <group
          name="WGT-rig_foot_ikR"
          position={[-0.16, 0.09, -0.01]}
          rotation={[-Math.PI, 0, 0]}
          scale={-0.18}
        />
        <group
          name="WGT-rig_VIS_thigh_ik_poleR"
          position={[-0.16, 0.63, 0.01]}
          rotation={[-Math.PI / 2, 0, 1.06]}
          scale={-1}
        />
        <group
          name="WGT-rig_thigh_tweakR"
          position={[-0.1, 1.09, -0.01]}
          rotation={[1.54, -0.13, 1.04]}
          scale={-0.12}
        />
        <group
          name="WGT-rig_thigh_tweakR001"
          position={[-0.13, 0.86, 0]}
          rotation={[1.54, -0.13, 1.04]}
          scale={-0.12}
        />
        <group
          name="WGT-rig_shin_tweakR"
          position={[-0.16, 0.63, 0.01]}
          rotation={[1.61, -0.01, 1.05]}
          scale={-0.14}
        />
        <group
          name="WGT-rig_shin_tweakR001"
          position={[-0.16, 0.36, 0]}
          rotation={[1.61, -0.01, 1.05]}
          scale={-0.14}
        />
        <group
          name="WGT-rig_foot_tweakR"
          position={[-0.16, 0.09, -0.01]}
          rotation={[0.38, 0, 0]}
          scale={-0.05}
        />
        <group
          name="WGT-rig_foot_spin_ikR"
          position={[-0.16, 0.02, 0.16]}
          rotation={[-Math.PI, 0, 0]}
          scale={-0.09}
        />
        <group
          name="WGT-rig_foot_heel_ikR"
          position={[-0.16, 0.09, -0.01]}
          rotation={[-Math.PI, 0, 0]}
          scale={-0.09}
        />
        <group
          name="WGT-rig_toe_ikR"
          position={[-0.16, 0.02, 0.16]}
          rotation={[0, 0, -Math.PI]}
          scale={-0.15}
        />
        <group name="WGT-rig_root" scale={0.91} />
        <mesh
          name="Cube004"
          castShadow
          receiveShadow
          geometry={nodes.Cube004.geometry}
          material={materials["Material.005"]}
          position={[0.56, 0.96, -1.64]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[-0.08, -0.05, -0.14]}
        />
        <mesh
          name="Cube022"
          castShadow
          receiveShadow
          geometry={nodes.Cube022.geometry}
          material={materials["Material.006"]}
          position={[2.16, 2.8, -3.03]}
          scale={[0.24, 0.24, 0.02]}
        />
        <mesh
          name="Cube023"
          castShadow
          receiveShadow
          geometry={nodes.Cube023.geometry}
          material={materials["Material.006"]}
          position={[1.4, 3.39, -3.03]}
          scale={[0.24, 0.24, 0.02]}
        />
        <mesh
          name="Cube007"
          castShadow
          receiveShadow
          geometry={nodes.Cube007.geometry}
          material={materials["Material.001"]}
          position={[-2.31, 0.84, 1.95]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[-0.28, -0.14, -0.42]}
        />
        <mesh
          name="Cube008"
          castShadow
          receiveShadow
          geometry={nodes.Cube008.geometry}
          material={materials["Material.008"]}
          position={[-2.76, 2.73, -0.58]}
          rotation={[0, 0, -Math.PI]}
          scale={[0.29, 0.03, 0.95]}
        />
        <mesh
          name="Cube025"
          castShadow
          receiveShadow
          geometry={nodes.Cube025.geometry}
          material={materials["Material.001"]}
          position={[1.4, 2.47, -3.03]}
          scale={[0.24, 0.24, 0.02]}
        />
        <mesh
          name="Cube003"
          castShadow
          receiveShadow
          geometry={nodes.Cube003.geometry}
          material={materials["Material.005"]}
          position={[-0.14, 0.96, -1.67]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[-0.36, -0.05, -0.16]}
        />
        <mesh
          name="Cube009"
          castShadow
          receiveShadow
          geometry={nodes.Cube009.geometry}
          material={materials["Material.008"]}
          position={[-2.76, 2.15, -0.58]}
          rotation={[0, 0, -Math.PI]}
          scale={[0.29, 0.03, 0.95]}
        />
        <mesh
          name="Cube026"
          castShadow
          receiveShadow
          geometry={nodes.Cube026.geometry}
          material={materials["Material.001"]}
          position={[2.16, 3.66, -3.03]}
          scale={[0.24, 0.24, 0.02]}
        />
        <mesh
          name="Cube010"
          castShadow
          receiveShadow
          geometry={nodes.Cube010.geometry}
          material={materials["Material.008"]}
          position={[-2.76, 1.52, -0.58]}
          rotation={[0, 0, -Math.PI]}
          scale={[0.29, 0.03, 0.95]}
        />
        <mesh
          name="Cube021"
          castShadow
          receiveShadow
          geometry={nodes.Cube021.geometry}
          material={materials["Material.008"]}
          position={[-0.17, 0.26, -0.63]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[-0.41, -0.09, -0.34]}
        />

        <mesh
          name="Cube030"
          castShadow
          receiveShadow
          geometry={nodes.Cube030.geometry}
          material={materials.shirt}
          position={[-0.66, 0.99, -2.58]}
          rotation={[Math.PI, -0.27, Math.PI]}
          scale={[-0.13, -0.03, -0.21]}
        />
        <mesh
          name="Cube032"
          castShadow
          receiveShadow
          geometry={nodes.Cube032.geometry}
          material={materials["Material.001"]}
          position={[-0.85, 0.96, -2.06]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[-0.01, -0.01, -0.14]}
        />
        <mesh
          name="Cube020"
          castShadow
          receiveShadow
          geometry={nodes.Cube020.geometry}
          material={materials["Material.001"]}
          position={[2.06, -0.12, -2.56]}
          scale={0.38}
        />
        <mesh
          name="Cube024"
          castShadow
          receiveShadow
          geometry={nodes.Cube024.geometry}
          material={materials["Material.006"]}
          position={[0.59, 2.76, -3.03]}
          scale={[0.24, 0.24, 0.02]}
        />
        <mesh
          name="Cube033"
          castShadow
          receiveShadow
          geometry={nodes.Cube033.geometry}
          material={materials["Material.006"]}
          position={[-1.16, 0.92, -2.55]}
          rotation={[-Math.PI, 0.24, -Math.PI]}
          scale={[-0.01, -0.01, -0.14]}
        />
        <mesh
          name="Cube027"
          castShadow
          receiveShadow
          geometry={nodes.Cube027.geometry}
          material={materials["Material.001"]}
          position={[0.59, 3.62, -3.03]}
          scale={[0.24, 0.24, 0.02]}
        />
        <mesh
          name="BezierCurve"
          castShadow
          receiveShadow
          geometry={nodes.BezierCurve.geometry}
          material={materials["Material.004"]}
          position={[1.22, 1.49, -2.41]}
          scale={0.72}
        >
          <mesh
            name="Plane"
            castShadow
            receiveShadow
            geometry={nodes.Plane.geometry}
            material={materials["Material.004"]}
            position={[-0.31, 0.41, -0.15]}
            rotation={[0, 0, -1.71]}
            scale={[0.11, 0.17, 0.08]}
          />
          <mesh
            name="Plane090"
            castShadow
            receiveShadow
            geometry={nodes.Plane090.geometry}
            material={materials["Material.004"]}
            position={[0.16, 0.24, -0.26]}
            rotation={[-2.78, 0, 1.71]}
            scale={[0.17, 0.17, 0.08]}
          />
          <mesh
            name="Plane091"
            castShadow
            receiveShadow
            geometry={nodes.Plane091.geometry}
            material={materials["Material.004"]}
            position={[-0.32, 0.15, -0.5]}
            rotation={[-0.11, -0.94, -1.55]}
            scale={[0.21, 0.21, 0.1]}
          />
          <mesh
            name="Plane092"
            castShadow
            receiveShadow
            geometry={nodes.Plane092.geometry}
            material={materials["Material.004"]}
            position={[0, -0.06, -0.07]}
            rotation={[-2.99, 1.13, 1.54]}
            scale={[0.08, 0.15, 0.07]}
          />
        </mesh>
        <mesh
          name="Cube029"
          castShadow
          receiveShadow
          geometry={nodes.Cube029.geometry}
          material={materials.shirt}
          position={[-0.66, 0.93, -2.58]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[-0.13, -0.03, -0.21]}
        />
        <mesh
          name="Cube031"
          castShadow
          receiveShadow
          geometry={nodes.Cube031.geometry}
          material={materials["Material.001"]}
          position={[-0.88, 1.01, -2.59]}
          rotation={[-Math.PI, 0, 2.48]}
          scale={[-0.13, -0.03, -0.21]}
        />
        <mesh
          name="Cube034"
          castShadow
          receiveShadow
          geometry={nodes.Cube034.geometry}
          material={materials["Material.006"]}
          position={[1.16, 1.1, -2.57]}
          scale={0.25}
        />

        <mesh
          name="Torus"
          castShadow
          receiveShadow
          geometry={nodes.Torus.geometry}
          material={nodes.Torus.material}
          position={[0.56, 0.98, -1.73]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={0.05}
        />
        <group
          name="Cube006"
          position={[-1.17, 0.47, 1.93]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[-1.6, -0.28, -0.82]}
        >
          <mesh
            name="Cube022_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube022_1.geometry}
            material={materials["Material.019"]}
          />
          <mesh
            name="Cube022_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube022_2.geometry}
            material={materials["Material.001"]}
          />
        </group>

        <mesh
          name="Cube014"
          castShadow
          receiveShadow
          geometry={nodes.Cube014.geometry}
          material={materials["Material.012"]}
          position={[-2.73, 3.09, -0.58]}
          scale={0.16}
        />
        <mesh
          name="Cube001"
          castShadow
          receiveShadow
          geometry={nodes.Cube001.geometry}
          material={materials.bake}
          position={[0.2, 1.61, -2.65]}
        />
        <mesh
          name="Cube005"
          castShadow
          receiveShadow
          geometry={nodes.Cube005.geometry}
          material={materials.bake}
          position={[-0.04, 0.78, -2.11]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[-1.61, -0.13, -0.9]}
        />
        <mesh
          name="Plane001"
          castShadow
          receiveShadow
          geometry={nodes.Plane001.geometry}
          material={materials.bake}
          position={[-0.2, -0.41, 0.76]}
          scale={[1.71, 1.06, 1.71]}
        />

        <mesh
          name="Cube016"
          castShadow
          receiveShadow
          geometry={nodes.Cube016.geometry}
          material={materials["Material.029"]}
          position={[-2.73, 3.09, -0.02]}
          scale={0.16}
        />
        <mesh
          name="Cube015"
          castShadow
          receiveShadow
          geometry={nodes.Cube015.geometry}
          material={materials["Material.032"]}
          position={[-2.73, 3.09, -1.13]}
          scale={0.16}
        />
        <mesh
          name="Cube012"
          castShadow
          receiveShadow
          geometry={nodes.Cube012.geometry}
          material={materials["Material.040"]}
          position={[-2.73, 2.5, -1.14]}
          scale={0.16}
        />
        <mesh
          name="Cube041"
          castShadow
          receiveShadow
          geometry={nodes.Cube041.geometry}
          material={materials["Material.036"]}
          position={[-2.73, 1.87, -0.58]}
          scale={0.16}
        />
        <mesh
          name="Cube013"
          castShadow
          receiveShadow
          geometry={nodes.Cube013.geometry}
          material={materials["Material.034"]}
          position={[-2.73, 2.5, -0.02]}
          scale={0.16}
        />
        <mesh
          name="Cube011"
          castShadow
          receiveShadow
          geometry={nodes.Cube011.geometry}
          material={materials["Material.038"]}
          position={[-2.73, 2.5, -0.58]}
          scale={0.16}
        />
        <mesh
          name="Cube018"
          castShadow
          receiveShadow
          geometry={nodes.Cube018.geometry}
          material={materials["Material.039"]}
          position={[-2.73, 1.87, -0.02]}
          scale={0.16}
        />
        <mesh
          name="Cube017"
          castShadow
          receiveShadow
          geometry={nodes.Cube017.geometry}
          material={materials["Material.035"]}
          position={[-2.73, 1.87, -1.14]}
          scale={0.16}
        />
        <mesh
          name="Cube048"
          castShadow
          receiveShadow
          geometry={nodes.Cube048.geometry}
          material={materials["Material.027"]}
          position={[1.4, 3.39, -3.03]}
          scale={[0.24, 0.24, 0.02]}
        />
        <mesh
          name="Cube050"
          castShadow
          receiveShadow
          geometry={nodes.Cube050.geometry}
          material={materials["Material.030"]}
          position={[0.59, 3.62, -3.03]}
          scale={[0.24, 0.24, 0.02]}
        />
        <mesh
          name="Cube019"
          castShadow
          receiveShadow
          geometry={nodes.Cube019.geometry}
          material={materials["Material.025"]}
          position={[2.16, 3.66, -3.03]}
          scale={[0.24, 0.24, 0.02]}
        />
        <mesh
          name="Cube046"
          castShadow
          receiveShadow
          geometry={nodes.Cube046.geometry}
          material={materials["Material.026"]}
          position={[2.16, 2.8, -3.03]}
          scale={[0.24, 0.24, 0.02]}
        />
        <mesh
          name="Cube049"
          castShadow
          receiveShadow
          geometry={nodes.Cube049.geometry}
          material={materials["Material.028"]}
          position={[1.4, 2.47, -3.03]}
          scale={[0.24, 0.24, 0.02]}
        />
        <mesh
          name="Cube051"
          castShadow
          receiveShadow
          geometry={nodes.Cube051.geometry}
          material={materials["Material.020"]}
          position={[0.59, 2.76, -3.03]}
          scale={[0.24, 0.24, 0.02]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/Objects/ModelV2.glb");

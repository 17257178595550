import React from "react";
import { PerspectiveCamera } from "@react-three/drei";

function Camera({CameraRef}) {
  return (
       <PerspectiveCamera
          position={[13, 20, 19]}
          rotation={[-0.74,0.38,0.37]}
          fov={40}
          makeDefault
          zoom={1}
          ref={CameraRef}
        />
  );
}

export default Camera;

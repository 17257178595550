import { Html } from '@react-three/drei';
import React, { useState, useEffect } from 'react';

function Loading() {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots === '...' ? '' : prevDots + '.'));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <mesh position={[1, 1, 1]} rotation={[-Math.PI / 2, 0, 0]} scale={[3, 3, 1]}>
      <Html position={[0, 0, 0.86]} transform distanceFactor={5.5}>
        <section className="loading">
          <h1>Loading{dots}</h1>
        </section>
      </Html>
    </mesh>
  );
}

export default Loading;
